import { combineReducers } from "redux";
import app from "./app/appReducers";
import auth from "./auth/authReducers";
import company from "./company/companyReducers";
import dispatch from "./dispatch/dispatchReducers";
import driver from "./driver/driverReducers";
import carrier from "./carrier/carrierReducers";
import equipment from "./equipment/equipmentReducers";
import dashboard from "./dashboard/dashboardReducers";
import invoice from "./invoice/invoiceReducers";
import business from "./business/businessReducers";
import tracking from "./tracking/trackingReducers";
import expense from "./expense/expenseReducers";
import firebase from "./firebase/firebaseReducers";
import address from "./address/addressReducers";
import eldbooks from "./hos/eldbooksReducers";
import paycheck from "./paycheck/paycheckReducer";
import keepTrucking from "./hos/keepTruckingReducers";
import samsara from "./hos/smReducers";
import intuit from "./intuit/intuitReducers";
import collab from "./collab/collabReducers";
import sms from "./sms/smsReducers";
import documentData from "./document/documentReducer";
import search from "./search/searchReducers";
import baccount from "./baccount/baccountReducers";
import dump from "./dumpDispatch/dumpDispatchReducer";
import emailer from "./emailer/emailerReducers";
import location from "./location/locationReducers";
import todo from "./todo/todoReducers";
import contact from "./contact/contactReducers";
import vendor from "./vendor/vendorReducers";
import creditLimit from "./creditLimit/creditLimitReducers";
import workLanes from "./worklane/workLanesReducers";
import repair from "./repair/repairReducers";
import income from "./incomeBuss/incomeReducers";
import notification from "./notification/notificationReducers";
import availability from "./availability/availabilityReducers";
import estimates from "./estimates/estimatesReducers";
import tripSheet from "./tripSheet/tripSheetReducers";
import timeSheet from "./timeSheet/timeSheetReducers";
import pushNotification from "./pushNotification/pushNotificationReducers";
import inventory from "./inventory/inventoryReducers";
import bol from "./bol/bolReducers";
import maintenance from "./maintenance/maintenanceReducers";
import weather from "./weather/weatherReducers";
import audit from "./audit/auditReducers";
import feedback from "./feedback/feedbackReducers";

export default combineReducers({
  appData: app,
  authData: auth,
  auditData: audit,
  driverData: driver,
  invoiceData: invoice,
  companyData: company,
  carrierData: carrier,
  dispatchData: dispatch,
  equipmentData: equipment,
  dashboardData: dashboard,
  businessData: business,
  trackingData: tracking,
  addressData: address,
  expenseData: expense,
  paycheckData: paycheck,
  firebaseData: firebase,
  intuitData: intuit,
  smsData: sms,
  documentData: documentData,
  searchData: search,
  eldbooksData: eldbooks,
  keepTruckingData: keepTrucking,
  baccountData: baccount,
  dumpData: dump,
  emailerData: emailer,
  locationData: location,
  todoData: todo,
  contactData: contact,
  creditLimitData: creditLimit,
  vendorData: vendor,
  workLanesData: workLanes,
  repairData: repair,
  incomeData: income,
  notificationData: notification,
  availabilityData: availability,
  estimatesData: estimates,
  tripSheetData: tripSheet,
  pushNotificationData: pushNotification,
  inventoryData: inventory,
  bolData: bol,
  maintenanceData: maintenance,
  weatherData: weather,
  timeSheetData: timeSheet,
  smData: samsara,
  collabData: collab,
  feedbackData: feedback
});
